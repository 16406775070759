// Here you can add other styles
.scss-series-form {
  .ql-editor {
    min-height: 200px;
  }
}

.react-bootstrap-table-page-btns-ul {
  margin-left: auto;
}

.custom-thumb-col {
  width: 6rem;
}

.custom-table-thumb {
  width: 100%;
}

.button-spinner {
  animation: rotate 1.5s linear infinite;
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
